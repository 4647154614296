<template>
  <div class="test" v-if="contentJson">
    <br>
    <br>
    <br>
    <br>
    <Card ref="card">
      <!--<div class="card__label"><span>HOME</span></div>-->
      <div class="test__content">
        <TickerHeadline>HELLO</TickerHeadline>
        <Grid :contents="[1,2,3,4]"></Grid>
        <Grid :contents="[1,2,3]"></Grid>
        <Grid :contents="[1,2]"></Grid>
        <Grid :contents="[1]"></Grid>
        <h4>GUDE</h4>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet.
        </p>

        <Button target="/projects">PROJECTS</Button>
        <Button target="/contact">CONTACT</Button>
        <Button target="/about">ABOUT</Button>
      </div>

      <!--<ProfilePicture></ProfilePicture>-->
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import TickerHeadline from '@/components/TickerHeadline'
import Button from '@/components/Button'
// import ProfilePicture from '../components/ProfilePicture'
import DataMixin from '@/mixins/DataMixin'
import AppearMixin from '@/mixins/AppearMixin'
import Grid from '../components/Grid'
export default {
  name: 'Test',
  components: {
    Card,
    Button,

    TickerHeadline,
    Grid
  },
  mixins: [DataMixin, AppearMixin],
  created () {
    this.fetchData('home')
  }
}
</script>
