<template>
  <div class="grid" :class="{['grid--'+gridStyle]:true}">
    <div class="grid__item" v-for="(item, index) in contents" :key="index">
      Lorem ipsum dolor sit amet
    </div>
  </div>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    contents: {
      type: Array,
      default () {
        return [1, 2, 3]
      }
    }
  },
  computed: {
    gridStyle () {
      const styles = ['full', 'half', 'third', 'quarter']
      return styles[this.contents.length - 1]
    }
  }
}
</script>
